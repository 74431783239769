<template>
  <component
    v-if="analysisObj!=null"
    ref="currentWorkflow"
    :is="componentName"
    :analysisObj="analysisObj"
  ></component>
</template>

<script>
import allAnalyses from '../../data/analysis.json'
import { useWorkspaceStore } from '@/stores/workspacestore'
import { useMenuStore } from '@/stores/menu'

export default {
  name: 'RWorkflow',
  components: {
    'tiles': () => import('@/components/ui/Tiles.vue'),
    'RMFA': () => import('@/components/rworkflows/RMFA.vue'),
    'RPCA': () => import('@/components/rworkflows/RPCA.vue'),
    'RPLS': () => import('@/components/rworkflows/RPLS.vue'),
    'RPLSDA': () => import('@/components/rworkflows/RPLSDA.vue'),
    'RClustering': () => import('@/components/rworkflows/RClustering.vue'),
    'RMissing': () => import('@/components/rworkflows/RMissing.vue'),
    'RSOM': () => import('@/components/rworkflows/RSOM.vue'),
    'RVariate': () => import('@/components/rworkflows/RVariate.vue'),
    'RNormalization': () => import('@/components/rworkflows/RNormalization.vue'),
    'REdit': () => import('@/components/rworkflows/REdit.vue'),
    'RDiff': () => import('@/components/rworkflows/RDiff.vue'),
    'RHeatmap': () => import('@/components/rworkflows/RHeatmap.vue')
  },
  data () {
    return {
      analysisObj: null
    }
  },
  computed: {
    analysisNameTocomponent: function () {
      let toReturn = {}
      for (var sec in allAnalyses) {
        let i = 0
        allAnalyses[sec].forEach(analysis => {
          analysis['ranalysisname'].forEach(ranalysisName => {
            toReturn[ranalysisName] = { wfclass: analysis['wfclass'],
              section: sec,
              index: i }
          })
          i++
        })
      }
      return toReturn
    },
    objectName: function () {
      return this.$route.params.name
    },
    componentName: function () {
      let componentName = null
      for (var key in this.analysisNameTocomponent) {
        if (this.analysisObj.meta.nature === key) {
          componentName = this.analysisNameTocomponent[key].wfclass
        }
      }
      return componentName
    }
  },
  setup () {
    const workspaceStore = useWorkspaceStore()
    const menuStore = useMenuStore()
    return { workspaceStore, menuStore }
  },
  mounted () {
    this.workspaceStore.getWorkflow()
      .then(() => {
        this.analysisObj = this.workspaceStore.analyses.filter(obj => obj.object_name === this.objectName)[0]
        let analysisName = this.analysisObj.meta.nature
        let section = this.analysisNameTocomponent[analysisName].section
        let index = this.analysisNameTocomponent[analysisName].index
        this.menuStore.setAnalysis(allAnalyses[section][index])
      })
  }
}
</script>
